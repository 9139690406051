<template>
  <div>
    <div class="head-card">
      <div class="head-card-top">
        <h4>{{ banner.title }}</h4>
        <feather-icon
          v-if="closing"
          v-b-tooltip.hover.top="$t('Close')"
          icon="XIcon"
          size="18"
          class="toggle-close cursor-pointer"
          @click="$router.go(-1)"
        />
      </div>
      <hr>
      <div class="head-card-bottom">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="flat-primary"
          class="btn-icon"
          :to="{ name: 'dashboard-campaigns-creatives-table' }"
          exact
        >
          <feather-icon
            icon="ImageIcon"
            size="16"
          />
          {{ $t('page.overview') }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="flat-primary"
          class="btn-icon"
          :to="{ name: 'dashboard-campaigns-creative-stats'}"
        >
          <feather-icon
            icon="BarChart2Icon"
            size="16"
          />
          {{ $t('Statistics') }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="flat-primary"
          class="btn-icon"
          :to="{ name: 'dashboard-campaigns-creative-edit' }"
        >
          <feather-icon
            icon="SettingsIcon"
            size="16"
          />
          {{ $t('Settings') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import getFormattedDate from '@/utils/date-formatter'
import Ripple from 'vue-ripple-directive'
import {
  BButton,
  VBTooltip,
} from 'bootstrap-vue'

export default {
  name: 'SiteToolbar',
  components: {
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    banner: {
      type: Object,
      required: true,
    },
    closing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      getFormattedDate,
    }
  },
}
</script>
